import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
// import JotformEmbed from 'react-jotform-embed';
import Seo from "../components/seo"
import Layout from "../components/layout"
import ReactMarkdown from 'react-markdown'
import { FreeQuoteForm } from "../components/freeQuoteForm";
const requestPage = (props) => (

  <Layout>
    <div className="bg-white drop-shadow-xl border-t-7 md:px-8 px-4 pb-4 mt-8 max-w-6xl mx-auto ">
      <h2 className="text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-8">Request Free Quote</h2>
      <div className="flex md:gap-20 gap-8 md:flex-nowrap flex-wrap">
        <div className="md:w-7/12 w-full">
          <ReactMarkdown
            children={props?.data?.markdownRemark?.frontmatter?.content}
            components={{
              p: ({ children }) => {
                return (
                  <p className="text-sm text-gray-700 font-light mb-4 leading-6">
                    {children}
                  </p>
                )
              },
              h2: ({ children }) => {
                return (
                  <h2 className='text-3xl text-gray-600 uppercase font-light title pb-5 mt-7 text-center mb-10 '>
                    {children}
                  </h2>
                )
              },
              h3: ({ children }) => {
                return (
                  <h3 className='md:text-2xl text-xl text-gray-600 uppercase  mt-5 font-medium title pb-5 mt-7 text-center mb-10 '>
                    {children}
                  </h3>
                )
              },
              h4: ({ children }) => {
                return (
                  <h4 className='text-2xl font-bold font3 mb-4'>{children}</h4>
                )
              },
              ul: ({ children }) => {
                return (
                  <ul className='md:mb-16 mb-6 pl-2 list-disc'>{children}</ul>
                )
              },
              li: ({ children }) => {
                return <li className='text-sm font-normal mb-3'>{children}</li>
              }
            }}
          />
          {/* <p className="text-sm text-gray-700 font-light mb-4 leading-6">Vinyl Fence Toronto is happy to provide you with a free consultation and price quote for your new fence installation project. We offer competitive pricing on our fencing systems, which include vinyl fences, aluminum fencing, simulated stone fences, chain link fence systems, and beyond. Our expert installers will get the job done right the first time we guarantee it! Request Free Quote</p>
                    <p className="text-sm text-gray-700 font-light mb-4 leading-6">Enter your information below and one of our representatives will be in touch to provide you with a free, no obligation consultation! We also invite you to contact us with any questions you may have; our GTA fencing installation professionals are always happy to help!</p>
                    <p className="text-sm text-gray-700 font-bold leading-6">please fill the below form and one of our Vinyl Fencing specialist will give you free estimate.</p> */}
          <div className="flex items-center">
            <FreeQuoteForm />
            {/* <JotformEmbed src="https://form.jotformeu.com/211047877115455" /> */}
          </div>
        </div>
        <div className="md:w-5/12 w-full text-center">
          <StaticImage className="mt-1" src="../assets/images/doors_collect.jpg" alt="" />
        </div>
      </div>
    </div>
  </Layout>
)
export const Head = (props) => <Seo
  title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle || 'Vinyl Fence Toronto | Durable & Stylish Fencing Solutions'}
  description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription}
  path={props.location.pathname}
/>


export const pageQuery = graphql`
  query RequestQuery{
    markdownRemark(frontmatter: {slug: {eq: "request-free-quote"}}) {
      frontmatter {
        content
        meta {
          seoTitle
          seoDescription
        }
      }
    }
  }
`;
export default requestPage