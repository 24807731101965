import React, { useEffect, useState } from "react"
export const FreeQuoteForm = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(true), 3000);
        return () => clearTimeout(timer);
    }, []);

    return isVisible ? (
        <iframe src="https://form.jotformeu.com/211047877115455" title="Free Quote Request Form" className="w-full min-h-[500px] border-0" loading="lazy" />
    ) : (
        <div className="w-full min-h-[500px] flex items-center justify-center bg-gray-100">
            <p>Loading form...</p>
        </div>
    );
};